const initialUsersState = {

};

export const usersReducer = (state = initialUsersState, action) => {
	switch (action.type) {
		default:
			return state;
	}
};
