const initialPostsState = {

};

export const postsReducer = (state = initialPostsState, action) => {
	switch (action.type) {
		default:
			return state;
	}
};

